import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image'

class OurAndroid extends React.Component {

  render() {
    return (
      <section className="android pt-100 mb-0">
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-left leftpad">

            <h2 className="heading-h2 text-center text-md-left"><span className="heading-h3">Our <span className="bluecolor">Android & <span className='transNone'>i</span>OS</span></span> Binance Clone App Development</h2>
            <p className="pharagraph head">Launch a user-friendly and eye-catching cryptocurrency exchange mobile app like Binance
            </p>
          </div>

          <div className="row table-content orderflex mobilewhitebox">
            <div className="col-md-12 col-lg-7 order2">
              <p className="pharagraph">Popular exchanges have their own app for all crypto users. If you are starting a cryptocurrency exchange, then launching a feature-rich app like Binance will be beneficial for your business. If you are a new crypto startup and want to stay unique in the competition, then it is not enough only to launch the website. So we are offering the finest app development services with premium outcomes. By using our service, you can launch a first-quality exchange app like Binance with an eye-catching user interface. Our app development service involves all the trending features along with high-end security features to make your app encrypted & secure.
              </p>
              <p className="pharagraph">As we can see the majority of the population is going towards mobile-based crypto trading. So initiating a cryptocurrency exchange app with outstanding features is one of the best moves to stay unique in the crypto business and reap a huge amount of profits. We have an expertized team of blockchain developers, testers, and designers who carefully build the Binance clone script with remarkable UI/UX and the latest modules. Mainly, the customized crypto exchange like Binance we develop is compatible with both IOS and Android platforms.
              </p>
              <ButtonComponent />
            </div>
            {/* <div className="col-md-12 col-lg-5 mobileappicon text-center order1">
              <img  width="568px" height="692px" src="https://coinsclone.mo.cloudinary.net/images/binance-new/our-android-ios-binance-clone-app.png" alt="Binance Clone App For iOS and Android" />
            </div> */}
            <div className="col-md-12 col-lg-5 and-img text-center">
                <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/binance-new/our-android-ios-binance-clone-app.png"
                        alt="Binance Clone App For iOS and Android"
                        width={525} />
            </div>
          </div>
          <div className="inner-container">
            <div className="platform">
              <div className="t-black plat-dev">Platforms Developed</div>
              <div className="playstoreicon">
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/android.svg" alt="Android" />
                  <span>Android</span> </div>
                <div className="play">
                  <img src="https://coinsclone.mo.cloudinary.net/images/apple.svg" alt="Apple" />
                  <span><span className="transNone">i</span>os</span> </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default OurAndroid    